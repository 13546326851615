// Generated by Framer (41c59c7)

import { addFonts, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["l4RulkSkZ"];

const serializationHash = "framer-T0nrb"

const variantClassNames = {l4RulkSkZ: "framer-v-16u3lvx"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "l4RulkSkZ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-16u3lvx", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"l4RulkSkZ"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><Link href={"https://erasmus-plus.ec.europa.eu/"} openInNewTab><Image as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 435, intrinsicWidth: 2109, pixelHeight: 435, pixelWidth: 2109, sizes: "190px", src: "https://framerusercontent.com/images/icmH8CJL8SwRNbLjZyo4bT1O0Q0.png", srcSet: "https://framerusercontent.com/images/icmH8CJL8SwRNbLjZyo4bT1O0Q0.png?scale-down-to=512 512w,https://framerusercontent.com/images/icmH8CJL8SwRNbLjZyo4bT1O0Q0.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/icmH8CJL8SwRNbLjZyo4bT1O0Q0.png?scale-down-to=2048 2048w,https://framerusercontent.com/images/icmH8CJL8SwRNbLjZyo4bT1O0Q0.png 2109w"}} className={"framer-lxnmwa framer-1i6ld65"} data-framer-name={"erasmus_"} layoutDependency={layoutDependency} layoutId={"a9zdv5SY6"}/></Link></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-T0nrb.framer-1i6ld65, .framer-T0nrb .framer-1i6ld65 { display: block; }", ".framer-T0nrb.framer-16u3lvx { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-T0nrb .framer-lxnmwa { flex: none; height: 40px; overflow: visible; position: relative; text-decoration: none; width: 190px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-T0nrb.framer-16u3lvx { gap: 0px; } .framer-T0nrb.framer-16u3lvx > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-T0nrb.framer-16u3lvx > :first-child { margin-left: 0px; } .framer-T0nrb.framer-16u3lvx > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 190
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerV_QtFkaOS: React.ComponentType<Props> = withCSS(Component, css, "framer-T0nrb") as typeof Component;
export default FramerV_QtFkaOS;

FramerV_QtFkaOS.displayName = "Erasmus+";

FramerV_QtFkaOS.defaultProps = {height: 40, width: 190};

addFonts(FramerV_QtFkaOS, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})